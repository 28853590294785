
import {
    COLOR_ATTRIBUTE,
    ENGINE_DESCRIPTION_ATTRIBUTE,
    FRAME_MATERIAL_ATTRIBUTE,
    FRAME_SHAPE_ATTRIBUTE,
    FRAME_SHAPE_ATTRIBUTE_2,
    FRAME_SIZE_ATTRIBUTE,
    REAR_DERAILLEUR_DESCRIPTION_ATTRIBUTE,
    WHEEL_SIZE_ATTRIBUTE,
    FRONT_FORK_DESCRIPTION_ATTRIBUTE, //  https://dev.azure.com/accell/Digital/_workitems/edit/99055
} from './attributes';
import { COLOR_GROUP_PARAM, PRICE_AMOUNT_PARAM, STOCK_PARAM } from './filters';
import { UIConfig, UISiteConfig, UITheme } from './uiConfig';

const config: UIConfig = {
    header: {
        darkTheme: new Set([]),
    },
    footer: {
        darkTheme: new Set([]),
    },
};
export const uiSiteConfig: UISiteConfig = {
    defaultHrefLang: 'de-de',
    awinEnabled: false,
    urlStructure: {
        productListing: {
            urlSegmentation: {
                enabled: false,
            },
        },
        productDetail: {
            path: {
                parentProduct: [{ productKey: 'name' }],
                childProduct: { variantAttributeOption: FRAME_SHAPE_ATTRIBUTE },
            },
            urlSegmentation: {
                enabled: false,
            },
        },
        productLineDetail: {
            urlSegmentation: {
                enabled: true,
            },
        },
        localeSeparator: '-',
    },
    fonts: {
        urlFile: ['/fonts/fonts.css'],
    },
    showPriceInternationalSalesChannel: {
        visible: true,
    },
    Product: {
        attributesKeyMap: {
                    default: {
                        [COLOR_ATTRIBUTE]: 'COLORCOM',
                        [FRAME_SHAPE_ATTRIBUTE]: 'UB-FRAMEKI',
                        [FRAME_SHAPE_ATTRIBUTE_2]: 'FRAMETYPE',
                        [FRAME_SIZE_ATTRIBUTE]: 'HEIGHTS',
                        [WHEEL_SIZE_ATTRIBUTE]: 'Wheel Size',
                        [FRAME_MATERIAL_ATTRIBUTE]: 'Frame material',
                        [REAR_DERAILLEUR_DESCRIPTION_ATTRIBUTE]: 'DerailleurRearDescription',
                        [ENGINE_DESCRIPTION_ATTRIBUTE]: 'MotorDescription',
                        [COLOR_GROUP_PARAM]: 'COLORBAS',
                        [FRONT_FORK_DESCRIPTION_ATTRIBUTE]: 'FrontForkDescription', //  https://dev.azure.com/accell/Digital/_workitems/edit/99055
                    },
                },
        optionsDisplayRules: [
            {
                attributeKeys: ["UB-FRAMEKI", "FRAMETYPE"],
                priority: 1,
                force: true,
            },
            {
                attributeKeys: ["COLORCOM"],
                priority: 2,
                force: true,
            },
            {
                attributeKeys: ["HEIGHTS"],
                priority: 3,
                force: false,
            },
            {
                attributeKeys: ["HEIGHT"],
                priority: 4,
                force: false,
            },
        ],
        breadcrumbsDepth: 3,
        showIconsForSingleProduct: [],
        smartfit: {
            visible: true,
            sizeAttribute: FRAME_SIZE_ATTRIBUTE,
        },
        geometry: {
            visible: true,
        },
    },
    ProductListing: {
        categoryPageSize: 90,
        readMoreButtonWithTitle: true,
        columns: {
            xxl: 3,
        },
        filters: {
            default: [
                STOCK_PARAM, // Stock (select in stock) filter
                'HEIGHTS',
                'UB-MRS',
                'UB-FRAMEKI', // Frame type
                'WHEEL', // Wheel size
                PRICE_AMOUNT_PARAM, // Price range filter
                'FRAMEMAT', // Frame material
                'COLORBAS', // Color basic
                'UB-MTBRD', // Drive unit brand
                'UB-BATCAP', // Battery capacity
                'UB-GRNBR', // Number of gears
                'UB-FFRKBRD'
            ],
        },
    },
    OCampaignListXL: {
        showBackgroundVideo: true,
    },
    OProductFull: {
        hero: {
            visible: true,
            theme: UITheme.Light,
        },

        reviews: {
            visible: true,
            exclude: [],
        },
        yotpoGallery: {
            visible: false,
        },
        images: {
            layout: 'carousel',
            containImages: false,
        },
        config: {
            theme: UITheme.Light,
            preferOnlineSales: false,
            oneyPriceRange: {},
        },
        description: {
            theme: UITheme.Light,
        },
        keyFeatures: {
            visible: true,
            theme: UITheme.Light,
            direction: 'horizontal',
            accordion: true,
        },
        geometry: {
            theme: UITheme.Light,
        },
        specifications: {
            theme: UITheme.Light,
        },
        relatedProducts: {
            theme: UITheme.Light,
        },
        flowboxGallery: {
            visible: true,
        },
        bidex: {
            default: false,
        },
    },
    Dealer: {
        hasDetailPage: false,
    },
    ProductConfiguratorOptions: {
        justifyOptionLayout: false,
    },
    Campaign: {
        theme: UITheme.Light,
        fullSizeHero: true,
    },
    ProductLine: {
        theme: UITheme.Light,
    },
    ProductLineListing: {
        theme: UITheme.Light,
    },
    SplashPage: {
        displayExtendedVersion: true,
        theme: UITheme.Light,
    },
    Home: {
        theme: UITheme.Light,
        yotpoGallery: {
            visible: false,
        },
    },
    Header: {
        centeredLogo: true,
    },
    StoreLocator: {
        filters: {
            default: ['premiumDealers', 'collectInStore'], 
        },
        filterByType: [],
        theme: UITheme.Light,
    },
    Checkout: {
        shippingLogic: {
            default: 'shippingMethodAlternate',
        },
    },
    Basket: {
        disableQuantitySelection: true,
    },
    OBrandSignature: {
        // TODO: add a shape
    },
    FullSizeBannerContent: {
        contentAlignmentConfig: 'centerMid', // This is overridden by the component's own contentAlignment prop from Storyblok
        fluidContainer: true,
        largeHeading: true,
    },
    PublicationListingContent: {
        textAlign: 'center',
    },
    BikeComparison: {
        theme: UITheme.Light,
    },
    ContactMethodsListingPage: {
        theme: UITheme.Light,
    },
    ContactMethodPage: {
        theme: UITheme.Light,
    },
    ShippingMethodsListingPage: {
        theme: UITheme.Light,
    },
    ShippingMethodPage: {
        theme: UITheme.Light,
    },
    PaymentMethodsListingPage: {
        theme: UITheme.Light,
    },
    PaymentMethodPage: {
        theme: UITheme.Light,
    },
    CookieSettings: {
        recaptcha: {
            oneTrustKeysToValidate: { default: ['C0001'] },
        },
        googleMaps: {
            oneTrustKeysToValidate: { default: ['C0001', 'C0003'] },
        },
        youtube: {
            oneTrustKeysToValidate: { default: ['C0001', 'C0003'] },
        },
        oneTrustAutoblockEnabled: true,
    },
};

export default config;
